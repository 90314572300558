import React, {useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import LanIcon from '@mui/icons-material/Lan';

function Header(){
    const [showMenu, setHidden] = useState(true);
    const toggleMenu = () => setHidden(value => !value);

    return (
        <header className="bg-gray-800 text-white">
            <nav className="flex items-center p-3 flex-wrap container mx-auto">
                <a href="#" className="p-2 mr-4 inline-flex items-center">
                    <LanIcon className="fill-current h-8 w-8 mr-2" />
                    <span className="text-lg font-bold tracking-wide">Nexus Support</span>
                </a>
                <button className="inline-flex p-3 hover:bg-gray-500 rounded ml-auto md:hidden" onClick={toggleMenu}>
                    <MenuIcon />
                </button>
                <div hidden={showMenu} className="w-full md:!inline-flex md:flex-row md:flex-grow md:w-auto " id="navigation">
                    <div className="flex flex-col md:inline-flex md:flex-row ml-auto">
                        <a href="#" className="px-3 py-2 rounded hover:text-gray-500">Home</a>
                        <a href="#" className="px-3 py-2 rounded hover:text-gray-500">Services</a>
                        <a href="#" className="px-3 py-2 rounded hover:text-gray-500">About</a>
                        <a href="#" className="px-3 py-2 rounded hover:text-gray-500">Contact</a>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;